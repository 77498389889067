<template>
  <div class="inquiry">
    <img class="inquiry_head" src="~@/assets/AIhardware/inquiry.png" />
    <div class="inquiry_head_type">
      <div class="inquiry_head_type_tltle">产品类型</div>
      <div class="inquiry_head_type_row">
        <van-radio-group
          v-model="submitForm.device_type"
          @change="headTypeChange"
        >
          <div class="inquiry_head_type_row_1">
            门锁
            <van-radio :name="1"></van-radio>
          </div>
          <div class="inquiry_head_type_row_2">
            入住机
            <van-radio :name="2"></van-radio>
          </div>
        </van-radio-group>
      </div>
    </div>
    <div class="inquiry_form">
      <van-field
        readonly
        clickable
        name="picker"
        right-icon="arrow-down"
        :value="submitForm.brandText"
        label="智能品牌"
        placeholder="请选择您要咨询的品牌"
        @click="showPicker = true"
      />
      <van-field
        v-model="submitForm.quantity_required"
        label="设备数量"
        placeholder="请输入您的需求设备数量"
      />
      <van-field v-model="submitForm.name" label="姓名" placeholder="请输入" />
      <van-field
        v-model="submitForm.phone"
        label="手机号"
        placeholder="请输入"
      />
    </div>
    <div style="padding: 20px 16px">
      <van-button type="primary" size="large" @click="submitAction"
        ><span style="font-weight: 600">我要询价</span></van-button
      >
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        value-key="name"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";
import { saveSmartDeviceEnquiry } from "@/services/AIhardware";
export default {
  data() {
    return {
      columns: [
          { name: "火河", value: "huohe" },
          { name: "优电", value: "youdian" },
          { name: "云丁", value: "yunding" }
      ],
      showPicker: false,
      submitForm: {
        brand: "",
        brandText: "",
        name: "",
        phone: "",
        device_type: 1,
        quantity_required: "",
        version: "9.8.1"
      },
    };
  },
  methods: {
    onConfirm(objItem) {
      this.submitForm.brandText = objItem.name;
      this.submitForm.brand = objItem.value;
      this.showPicker = false;
    },
    headTypeChange(val) {
      if (val === 1) {
        this.columns = [
          { name: "火河", value: "huohe" },
          { name: "优电", value: "youdian" },
          { name: "云丁", value: "yunding" },
        ];
      } else {
        this.columns = [{ name: "睿沃", value: "ruiwo" }];
      }
    },
    submitAction() {
      let { innid, brand, name, phone, device_type, quantity_required,version } =
        this.submitForm;
      if (!brand) {
        Toast.fail("品牌不可为空");
        return false;
      } else if (!quantity_required) {
        Toast.fail("需求数量不可为空");
        return false;
      } else if (!name) {
        Toast.fail("申请人姓名不可为空");
        return false;
      } else if (!phone) {
        Toast.fail("手机号不可为空");
        return false;
      }
      console.log(this.submitForm);
      saveSmartDeviceEnquiry({innid, brand, name, phone, device_type, quantity_required,version}).then(() => {
        this.$router.go(-1);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.inquiry {
  font-size: 0;
  .inquiry_head {
    width: 100%;
  }
  .inquiry_head_type {
    background: #ffffff;
    display: flex;
    height: 88px;
    .inquiry_head_type_tltle {
      height: 44px;
      font-size: 14px;
      font-weight: 400;
      color: #262626;
      padding: 12px 16px;
      width: 104px;
    }
    .inquiry_head_type_row {
      width: calc(100% - 120px);
    }
    .inquiry_head_type_row_1 {
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      height: 44px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }
    .inquiry_head_type_row_2 {
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      height: 44px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }
  }
  .inquiry_form {
    margin-top: 12px;
    background: #ffffff;
  }
}
</style>
